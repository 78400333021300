<template>
    <div class="all-medal-content md:flex-col md:gap-16">
        <div class="content-menu md:w-full md:min-w-full md:max-w-full md:flex-row">
            <div class="menu-item md:w-auto md:px-20 md:py-2 md:h-36 md:rounded-full menu-item-active"  @click="$router.go(-1)">
                <img  src="../../assets/img/default/goback.png" alt="" />
                <span>{{lang=='en'?'Asset':'资产'}}</span>
            </div>
        </div>
        <ListContent />
    </div>
</template>
<script>
import ListContent from './ListContent.vue';
import { mapState } from 'vuex';
export default {
    components: {
        ListContent,
    },
    data() {
        return {
            tab: 1,
        };
    },
    computed: {
        ...mapState(['screenWidth', 'address','lang']),
    },
    async created() {},
    mounted() {
    },
    watch: {},
    methods: {
    },
};
</script>
<style lang="scss" scoped>
.all-medal-content {
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 150px);
    display: flex;
    align-items: flex-start;
    // justify-content: space-between;
    padding: 50px 75px 80px;
    display: flex;
    gap: 40px;

    @screen md {
        padding: 16px;
    }

    .content-menu {
        /* margin-right: 40px; */
        width: 196px;
        min-width: 196px;
        max-width: 196px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        .menu-item {
            cursor: pointer;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;
            border-radius: 8px;
            transition: all 0.3s;

            img {
                width: 24px;
                margin-right: 16px;
            }

            span {
                display: inline-block;
                color: #a2a3a5;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 16px;
                /* 100% */
                letter-spacing: -0.4px;
            }
        }

        .menu-item-active {
            background: rgba(255, 255, 255, 0.1);

            span {
                color: #fff;
            }
        }
    }

    .content-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 24px;
        flex: 1;

        .sbt-item {
            width: 283px;
            /* height: 336px; */
            border-radius: 16px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            background: #1a1a1d;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            z-index: 0;

            .isAcquire {
                position: absolute;
                right: 5%;
                top: 5%;
                width: 38px;
                height: 38px;

                @screen md {
                    width: 28px;
                    height: 28px;
                }
            }

            .nft-img {
                border-radius: 16px;
                overflow: hidden;
                width: 281px;
                /* height: 281px; */
                /* min-height: 281px;
					max-height: 281px; */

                img {
                    border-radius: 16px;
                    width: 100%;
                    /* height: 100%; */
                    object-fit: cover;
                }
            }

            .nft-name {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 54px;

                .isKt {
                    margin-right: 10px;
                    width: 24px;
                    height: 24px;
                }

                span {
                    color: rgba(255, 255, 255, 0.8);
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 590;
                    line-height: 14px;
                    /* 100% */
                    letter-spacing: -0.4px;
                }
            }

            .nft-name-md {
                display: none;
                color: #fff;

                .nft-detail-md {
                    padding: 0 8px 8px;

                    h1 {
                        .isKt {
                            margin-right: 10px;
                            width: 24px;
                            height: 24px;
                        }

                        color: rgba(255, 255, 255, 0.8);
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 16px;
                        /* 100% */
                        letter-spacing: -0.4px;
                    }

                    p {
                        margin: 0;
                        color: rgba(255, 255, 255, 0.8);
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        /* 133.333% */
                        letter-spacing: -0.8px;
                    }
                }

                .nft-claim-md {
                    width: 100%;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        line-height: 36px;
                        border-radius: 0px 0px 16px 16px;
                        border: 1px solid rgba(255, 255, 255, 0.1);
                        background: linear-gradient(90deg, #031bde 0%, #4c7cf9 100%);
                        border: none;
                    }
                }
            }

            .detail-btn {
                width: 100%;
                position: absolute;
                bottom: -142px;
                left: 0;
                z-index: 2;
                overflow: hidden;
                transition: all 0.3s;

                .detail-box {
                    height: 100px;
                    padding: 16px 12px;
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    border-radius: 16px 16px 0px 0px;
                    border-left: none;
                    border-right: none;
                    border-bottom: none;
                    background: rgba(255, 255, 255, 0.09);
                    backdrop-filter: blur(15px);

                    span {
                        color: #fff;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 14px;
                        border: none;
                        /* 100% */
                    }

                    p {
                        margin: 0;
                        margin-top: 10px;
                        color: rgba(255, 255, 255, 0.8);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 18px;
                        /* 128.571% */
                    }
                }

                .btn-box {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 42px;
                    border-radius: 0px 0px 16px 16px;
                    background: linear-gradient(90deg, #031bde 0%, #4c7cf9 100%);

                    /* backdrop-filter: blur(15px); */
                    span {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                        text-align: center;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 590;
                        line-height: 42px;
                        /* 100% */
                        letter-spacing: -0.4px;
                    }
                }
            }

            &:hover {
                .detail-btn {
                    bottom: 0;
                    /* transform: translateY(0); */
                }
            }
        }
    }
}
</style>
