<template>
    <div class="all-medal-content2 flex-column">
        <div class="flex-between" :style="{ 'padding-bottom': screenWidth > 900 ? '30px' : '20px' }">
            <p class="flex" style="margin-bottom: 0px">
                <span style="font-size: 20px">{{ coinConfig[coinid].coinname }}{{ type == 1 ? lang=='en'?'Detail':'明细' : lang=='en'?'Withdrawal record':'提现记录' }}</span>
            </p>
            <custom-pagination v-if="totalCount" :totalItems="totalCount" :itemsPerPage="pageSize" @page-change="onChange" />
        </div>
        <div class="flex-1">
            <ul v-if="arrList.length > 0" class="grid-container3" :class="[screenWidth > 900 ? 'grid-container3' : 'mb12']">
                <li v-for="item in arrList" class="box1 recordCard" :class="[screenWidth > 900 ? '' : 'box1aaa']">
                    <div class="flex-between">
                        <p class="flex">
                            <img src="../../assets/img/default/record.png" alt="" />
                            <span style="padding-right: 4px; padding-left: 4px">{{ type == 1 ? TradeType[item.tradetype] : lang=='en'?'Withdrawal Amount:':'提现金额：' }}</span>
                            <template v-if="type == 1">
                                <span v-if="item.income">+ {{ item.income }}</span>
                                <span v-else>- {{ item.applyamount }}</span>
                            </template>
                            <template v-else>
                                <span>{{ item.amount }}</span>
                            </template>
                        </p>
                        <template v-if="type == 1">
                            <span class="state" v-if="item.state == 1">{{lang=='en'?'Done':'已完成'}}</span>
                            <span class="state state2" v-else>{{lang=='en'?'Not processed':'未处理'}}</span>
                        </template>
                        <template v-if="type == 2">
                            <span class="state" v-if="item.state == 1">{{lang=='en'?'Payment has been made':'已打款'}}</span>
                            <span class="state state1" v-if="item.state == 2">{{lang=='en'?'Rejected':'已驳回'}}</span>
                            <span class="state state2" v-if="item.state == 0">{{lang=='en'?'To be reviewed':'待审核'}}</span>
                        </template>
                    </div>
                    <p class="flex-column">
                        <template v-if="type == 2">
                            <span>{{lang=='en'?'Destination Address:':'目标地址：'}}{{ item.toaddress | addressF }}</span>
                            <span>{{lang=='en'?'Amount received:':'到账金额：'}}{{ item.amount }} {{ coinConfig[coinid].coinname }}</span>
                            <span>{{lang=='en'?'Fee:':'手续费：'}}{{ item.fee }} {{ coinConfig[coinid].coinname }}</span>
                            <span>{{lang=='en'?'Time:':'时间：'}}{{ item.ctime.replace('T', ' ') }}</span>
                        </template>
                        <template v-if="type == 1">
                            <span>{{ item.tradedescription }}</span>
                            <span>{{lang=='en'?'Time:':'时间：'}}{{ item.createtime.replace('T', ' ') }}</span>
                        </template>
                    </p>
                    <p class="remark" v-if="type == 2 && item.state == 2">
                        <span>{{lang=='en'?'Reasons for rejection:':'驳回原因：'}}</span>
                        <span class="">{{ item.remark }}</span>
                    </p>
                </li>
            </ul>
            <empty v-if="!isLoading" :data="arrList" />
        </div>
    </div>
</template>
<script>
import CustomPagination from '@/components/CustomPagination';
import empty from '@/components/empty';
import { mapState } from 'vuex';
import { GetUserBalanceRecord, GetWithdrawApply } from '@/api/Trade.js';
export default {
    inject: ['reload'],
    data() {
        return {
            type: 1, // 1 资金记录  2  提现申请记录
            coinid: 1,
            arrList: [],
            pageIndex: 1,
            pageSize: 5,
            totalCount: 0,
            isLoading: false,
        };
    },
    filters: {
        addressF(v) {
            if (!v) {
                return '-';
            }
            return v.replace(/^([A-Za-z0-9]{5})[A-Za-z0-9]{33}([A-Za-z0-9]{4})$/, '$1…$2');
        },
    },
    components: { empty, CustomPagination },
    computed: {
        ...mapState(['screenWidth', 'address', 'info', 'coinConfig', 'enum','lang']),
        TradeType() {
            return this.enum['TradeType'];
        },
    },
    async mounted() {
        this.coinid = this.$route.query.coinid;
        this.type = this.$route.query.type || 1;
        this.init();
    },
    watch: {},
    methods: {
        async onChange(e) {
            this.pageIndex = e;
            this.getData();
        },
        async init() {
            this.getData();
        },
        async getData() {
            this.isLoading = true;
            let res =
                this.type == 1
                    ? await GetUserBalanceRecord({
                          coinid: this.coinid,
                          // tradetype:0,
                          pageIndex: this.pageIndex,
                          pageSize: this.pageSize,
                      })
                    : await GetWithdrawApply({
                          pageIndex: this.pageIndex,
                          pageSize: this.pageSize,
                      });
            this.isLoading = false;
            this.totalCount = res.totalCount;
            this.arrList = [];
            this.arrList = res.list;
        },
    },
};
</script>
<style lang="scss" scoped>
.all-medal-content2 {
    width: 100%;
    flex: 1;
    display: flex;
    margin: 0px 0px !important;
}
.box1aaa {
    padding: 16px 20px !important;
}
.box3 {
    background: #1a1a1d;
    border-radius: 15px;
    padding: 16px 20px;
    & > div:nth-of-type(1) {
        border-bottom: 1px solid #313131;
    }
}
.mb12 {
    row-gap: 12px !important;
    .cardBalance {
        padding: 16px 20px !important;
    }
}
.box2 {
    height: 100px;
    background: linear-gradient(90deg, #041cde 0%, #4b7bf8 100%);
    border-radius: 20px;
}
.grid-container2 {
    row-gap: 12px !important;
    grid-template-columns: repeat(1, 1fr) !important;
}
.grid-container3 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}
.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}
.recordCard {
    & > div {
        padding-bottom: 16px;
        & > p {
            margin-bottom: 0px;
            img {
                width: 24px;
                height: 24px;
            }
            span {
                font-size: 15px;
            }
        }
        border-bottom: 1px solid #313131;
    }
    & > p {
        padding-top: 4px;
        margin-bottom: 0px;
        font-size: 12px;
        span {
            padding: 4px 0px;
            color: #999999;
        }
    }
}
.state {
    font-size: 14px;
}
.state1 {
    color: red;
}
.state2 {
    color: #041cde;
}
.remark {
    span {
        color: red !important;
    }
}
</style>
